import React, { useState } from "react";

import BlockContent from "@sanity/block-content-to-react";
import { graphql } from "gatsby";
import Cookies from "js-cookie";
import PropTypes from "prop-types";

import ContentContainer from "../../components/ContentContainer/ContentContainer";
import Seo from "../../components/Seo/Seo";
import Header from "../layouts/Header";
import MainLayout from "../layouts/MainLayout";

const matomoSettings = {
  checkToOptIn: "Diese Checkbox aktivieren f\u00fcr Opt-In.",
  consentCookieName: "mtm_consent",
  consentRemovedCookieName: "mtm_consent_removed",
  cookieDomain: null,
  cookiePath: null,
  cookieSameSite: "Lax",
  cookiesDisabled: typeof window !== "undefined" ? (!navigator || !navigator.cookieEnabled) : null,
  optOutComplete: "Opt-out abgeschlossen; Ihre Besuche auf dieser Webseite werden nicht vom Webanalysetool erfasst.",
  optOutCompleteBis: "Bitte beachten Sie, dass auch der Matomo-Deaktivierungs-Cookie dieser Webseite gel\u00f6scht wird, wenn Sie die in Ihrem Browser abgelegten Cookies entfernen. Au\u00dferdem m\u00fcssen Sie, wenn Sie einen anderen Computer oder einen anderen Webbrowser verwenden, die Deaktivierungsprozedur nochmals absolvieren.",
  optOutErrorNoCookies: "Die Tracking opt-out Funktion ben\u00f6tigt aktivierte Cookies.",
  uncheckToOptOut: "Diese Checkbox abw\u00e4hlen f\u00fcr Opt-Out.",
  useSecureCookies: true,
  youAreNotOptedOut: "Ihr Besuch dieser Webseite wird aktuell von der Matomo Webanalyse erfasst.",
  youAreOptedOut: "Ihr Besuch dieser Webseite wird aktuell von der Matomo Webanalyse nicht erfasst.",
  youMayOptOut2: "Sie haben die M\u00f6glichkeit zu verhindern, dass von Ihnen hier get\u00e4tigte Aktionen analysiert und verkn\u00fcpft werden.",
  youMayOptOut3: "Dies wird Ihre Privatsph\u00e4re sch\u00fctzen, aber wird auch den Besitzer daran hindern, aus Ihren Aktionen zu lernen und die Bedienbarkeit f\u00fcr Sie und andere Benutzer zu verbessern.",
};

const LegalPage = ({ data: { page } }) => {
  const hasConsent = () => {
    const consentCookie = Cookies.get(matomoSettings.consentCookieName);
    const removedCookie = Cookies.get(matomoSettings.consentRemovedCookieName);

    if (!consentCookie && !removedCookie) {
      return true; // No cookies set, so opted in
    }

    if (removedCookie && consentCookie) {
      Cookies.remove(matomoSettings.consentCookieName); // Both cookies set, so opted out

      return false;
    }

    return (typeof consentCookie !== "undefined" && consentCookie !== 0);
  };

  const [consent, setConsent] = useState(hasConsent());

  const _paq = typeof window !== "undefined" && window._paq ? window._paq : [];

  const cookieAttributes = {
    domain: matomoSettings.cookieDomain,
    path: "/",
    sameSite: matomoSettings.cookieSameSite,
    secure: true,
  };

  const updateConsent = ({ target: { checked: given } }) => {
    setConsent(given);

    if (given) {
      Cookies.remove(matomoSettings.consentRemovedCookieName, cookieAttributes);
      Cookies.set(
        matomoSettings.consentCookieName,
        new Date().getTime(),
        10950,
        cookieAttributes,
      );

      _paq.push(["forgetUserOptOut"]);

      return;
    }

    Cookies.remove(matomoSettings.consentCookieName, cookieAttributes);
    Cookies.set(
      matomoSettings.consentRemovedCookieName,
      new Date().getTime(),
      10950,
      cookieAttributes,
    );

    _paq.push(["optUserOut"]);
  };

  return (
    <MainLayout
      header={(
        <Header
          headline={page.title}
          bgImageUrl="url(/images/backgrounds/white.webp)"
          variant="overlay"
          theme="white"
        />
      )}
    >
      <ContentContainer>
        {page._rawBody && (
          <BlockContent
            blocks={page._rawBody}
            className="lg:p-66
              prose-h2:text-h4 prose-h2:mb-24 prose-h2:mt-48 prose-h2:text-black
              prose-h3:text-h4 prose-h3:mb-30 prose-h3:mt-24 prose-h3:text-black text-grey
              prose-h4:text-h4 prose-h4:mb-6 prose-h4:mt-24 prose-h4:text-black
              prose-p: mb-36 [&>*:first-child]:mt-0"
          />
        )}

        <div>
          <p>{matomoSettings.youMayOptOut2} {matomoSettings.youMayOptOut3}</p>

          <input
            onChange={updateConsent}
            type="checkbox"
            checked={consent}
            id="trackVisits"
          />

          <label htmlFor="trackVisits">
            <strong>
              <span>
                {consent ? (
                  <>{matomoSettings.youAreNotOptedOut} {matomoSettings.uncheckToOptOut}</>
                ) : (
                  <>{matomoSettings.youAreOptedOut} {matomoSettings.checkToOptIn}</>
                )}
              </span>
            </strong>
          </label>
        </div>
      </ContentContainer>
    </MainLayout>
  );
};

export const query = graphql`
  query ($id: String!) {
    page: sanityLegalDocument(id: {eq: $id}) {
      id
      title
      slug {
        current
      }
      _rawBody(resolveReferences: {maxDepth: 10})
    }
  }
`;

LegalPage.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

const seo = ({ data }) => (
  <Seo data={data.page.Metadata} />
);

seo.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export const Head = seo;

export default LegalPage;
